import { useState, useEffect } from 'react';
import { VStack, Box, Text, Input, Button, Spinner } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa from 'papaparse'; // Library for CSV parsing
import Navbar from '../../components/navbar';
import api from '../../utils/api';

const OnboardScreen = () => {
  const [words, setWords] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();
  const navigate= useNavigate();
  const locationParts = location.pathname.split('/');
  const organisationId = locationParts[locationParts.length - 1];

  useEffect(() => {
    setLoading(true);
    async function fetchKeywordsForOrganisation() {
      setLoading(true);
      const keywordsResp = await api.getGeneratedKeywords(organisationId);
      if (
        keywordsResp &&
        keywordsResp.ok &&
        keywordsResp.data &&
        keywordsResp.data.success
      ) {
        const words = keywordsResp.data.data.map((el) => el.word);
        setWords(words);
        setKeywords(keywordsResp.data.data)
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchKeywordsForOrganisation();
  }, [organisationId]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        Papa.parse(file, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            console.log(result.data);
            setCsvData(result.data);
          },
          error: (error) => {
            console.error('CSV parsing error:', error);
          },
        });
      } else {
        alert('Please upload a CSV file.');
      }
    }
  };

  const handleSampleCsvButton = (e) => {
    e.preventDefault();

    const sampleData = keywords.map((keyword) => ({
      keywordId: keyword.keywordId,
      word: keyword.word,
      strength: 55,
      difficuly: 20,
      volume: 1500,
    }));

    const csvContent = Papa.unparse(sampleData, {
      header: true,
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `sample_${organisationId}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        'Your browser does not support the download functionality. Please try a different browser.'
      );
    }
  };

  const handleSubmit = async () => {
    if (csvData && csvData.length) {
      console.log(`submitting`, csvData);
      const keywordSubmitResp = await api.submitKeywordDetails({
        organisationId,
        keywordDetails: csvData
      })
      if (keywordSubmitResp && keywordSubmitResp.ok) {
        console.log(`subimit resp`, keywordSubmitResp)
      }
      navigate('/waitlist');
    } else {
      alert('Please upload a CSV file.');
    }
  };

  return (
    <VStack spacing={4} align="center">
      {isLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <Navbar />
          <Box w="80%">
            <Text fontSize="xl">Keywords:</Text>
            <Text>{words.join(', ')}</Text>
            <Button
              mt={4}
              colorScheme="blue"
              onClick={handleSampleCsvButton}
              disabled={isLoading}
            >
              Download Sample CSV File
            </Button>
            <Text mt={4} fontSize="xl">
              Upload CSV File:
            </Text>
            <Input
              type="file"
              onChange={handleFileUpload}
              accept=".csv"
            />
            <Button
              mt={4}
              colorScheme="blue"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
    </VStack>
  );
};

export default OnboardScreen;

import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Spinner
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can add your login logic here
    console.log('Email:', email);
    console.log('Password:', password);
    // For demo purposes, just logging the values
    setLoading(true);
    const loginResp = await api.postLogin({ email, password });
    if (loginResp.ok && loginResp.data && loginResp.data.success){
      api.setTokenHeader(loginResp.data.data.token)
      setLoading(false);
      navigate('/waitlist')
    } else {
      alert('something went wrong!')
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner size="xl" />
      ) : (
        <VStack spacing={4} align="center">
          <Box w="300px">
            <form onSubmit={handleSubmit}>
              <FormControl id="email" isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </FormControl>
              <FormControl id="password" isRequired mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </FormControl>
              <Button
                disabled={isLoading}
                type="submit"
                colorScheme="blue"
                mt={4}
                w="100%"
              >
                Login
              </Button>
            </form>
          </Box>
        </VStack>
      )}
    </>
  );
  
};

export default Login;

import { VStack, Box, Text, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import Navbar from '../../components/navbar';

const Home = () => {
  // Sample stats data (replace with your actual data)
  const statsData = {
    totalUsers: 1000,
    activeUsers: 750,
    revenue: 15000,
    newOrders: 120,
  };

  return (
    <>
    <Navbar/>
    <VStack spacing={4} align="center" mt={8}>
      <Box w="80%">
        <Text fontSize="3xl" fontWeight="bold" mb={4}>
          Stats Reports
        </Text>
        <Stat>
          <StatLabel>Total Users</StatLabel>
          <StatNumber>{statsData.totalUsers}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Active Users</StatLabel>
          <StatNumber>{statsData.activeUsers}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Revenue</StatLabel>
          <StatNumber>${statsData.revenue}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>New Orders</StatLabel>
          <StatNumber>{statsData.newOrders}</StatNumber>
        </Stat>
      </Box>
    </VStack>
    </>
  );
};

export default Home;

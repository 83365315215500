import { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import Navbar from '../../components/navbar';

const WaitlistPage = () => {
  const [waitlistData, setWaitlistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate= useNavigate();

  useEffect(() => {
    // Simulating a delay for data fetching
    const fetchData = async () => {
        const resp = await api.getWaitlistedUsers();
        if (resp.ok && resp.data && resp.data.success) {
            const waitlisedUsers = resp.data.data;
            setWaitlistData(waitlisedUsers);
            setLoading(false);
        } else {
            setLoading(false);
            navigate('/login')
        }
    };
    fetchData();
  }, []);

  const handleOnboard = (id, onboarding_status) => {
    if (onboarding_status !== 'KEYWORDS_GATHERED') {
      alert('Action not allowed, Please raise a special request!');
      return;
    }
    navigate(`/onboard/${id}`);
  };

  const handleReject = (id) => {
    // Make an API call to delete the entry with the given ID
    // Replace this with your actual API call
    console.log(`Delete entry with ID: ${id}`);
    alert('Feature coming soon!');
  };

  return (
    <VStack spacing={4} align="center">
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <>
        <Navbar/>
        <Table variant="simple" w="80%">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Business Website</Th>
              <Th>Created At</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {waitlistData.map((item) => (
              <Tr key={item.id}>
                <Td>{item.name}</Td>
                <Td>{item.email}</Td>
                <Td>{item.business_website}</Td>
                <Td>{item.created_at}</Td>
                <Td>{item.onboarding_status}</Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => handleOnboard(item.organisation_id, item.onboarding_status)}
                    mr={2}
                  >
                    Onboard
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => handleReject(item.id)}
                  >
                    Reject
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        </>
      )}
    </VStack>
  );
};

export default WaitlistPage;

import apisauce from 'apisauce';

const BASE_URL = 'https://api.brandos.company/';

const TOKEN_KEY = 'accessToken'; // Key to store the access token in local storage

export function getError(problem) {
  switch (problem) {
    case apisauce.NETWORK_ERROR:
      return { message: 'Network not available' };
    case apisauce.TIMEOUT_ERROR:
      return { message: 'Server timeout' };
    case apisauce.CONNECTION_ERROR:
      return { message: 'Server not available' };
    default:
      return { message: 'An unknown error has occurred' };
  }
}

const create = (baseURL = BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Accept-Version': 'v1.2.18',
    },
    timeout: 10000,
  });

  const setTokenHeader = (accessToken) => {
    api.setHeader('Authorization', `Bearer ${accessToken}`);
    // Save token to local storage for reuse
    localStorage.setItem(TOKEN_KEY, accessToken);
  };

  const clearTokenHeader = () => {
    api.deleteHeader('Authorization');
    // Clear token from local storage
    localStorage.removeItem(TOKEN_KEY);
  };

  // Check if there's a token in local storage and set it in the header
  const savedToken = localStorage.getItem(TOKEN_KEY);
  if (savedToken) {
    setTokenHeader(savedToken);
  }

  // Login, logout, get logged in user
  const postLogin = (body) => {
    clearTokenHeader();
    return api.post('api/login', body);
  };

  const getWaitlistedUsers = () => api.get('/api/cms/waitlisted-users');
  const getGeneratedKeywords = (organisationId) => api.get(`/api/cms/generated-keywords/${organisationId}`);
  const submitKeywordDetails = (keyworDetailsBody) => api.post(`/api/cms/submit-keyword-strength`, keyworDetailsBody);

  return {
    setTokenHeader,
    clearTokenHeader,
    postLogin,
    getWaitlistedUsers,
    getGeneratedKeywords,
    submitKeywordDetails,
  };
};

export default create();

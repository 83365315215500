import { Link } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

const Navbar = () => {
  return (
    <Box bg="blue.500" color="white" p={4}>
      <Flex align="center" maxW="1200px" mx="auto">
        <Link to="/" style={{ marginRight: '20px' }}>
          Home
        </Link>
        <Link to="/waitlist" style={{ marginRight: '20px' }}>
          Waitlist
        </Link>
        {/* Add other navigation links as needed */}
      </Flex>
    </Box>
  );
};

export default Navbar;
